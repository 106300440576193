.title, p {
  color: #000;
  margin-top: 20px;
  margin-left: 5px;
}

img {
  width: 18px;
  height: 18px;
}

.left-container {
  display: flex;
  align-items: center;
}

.copy {
  background: #D4E4E9 !important;
}

.infos, a {
  text-decoration: none;
  color: #000 !important;
}

.color-name {
    background: linear-gradient(0deg, rgba(148, 187, 200, 0.4), rgba(148, 187, 200, 0.4));

}
